import React from "react";
import { connect } from "react-redux";
import './ProductPage.css';

class ProductPage extends React.Component {
  constructor(props) {
    super(props)
    let {data} = this.props;
    this.state = {  data }
  }
  render (){
    let {data}=this.state;
    return (
      <div className={"grid-item"} >
          {data}
      </div>
    )
  }
}
let mapStateToProps = state => {
    return {
      count: state.counter.count,
    }
  }
  
  let mapDispatchToProps = dispatch => {
    return { 
    }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(ProductPage)
import React from "react";
import cyl_example from "./cyl_example.png";
import cyl_example_o_prasteni from "./cyl_example_o_prasteni.png";
import cyl_example_chistach from "./cyl_example_chistach.png";
import cyl_example_zatvor from "./cyl_example_zatvor.png";
import cyl_example_uplatnitel_za_butalo from "./cyl_example_uplatnitel_za_butalo.png";

import "./App.css";
import { connect } from "react-redux";
import * as productData from "./data/products.json";
import { Accordion, ProductCard } from "./components";
// var data = require('json!../data/yourfile.json');
import {
  increaseCounter,
  decreaseCounter,
} from "./redux/Counter/counter.actions";
import Select from "./components/Select/Select";

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: productData.default,
      types: [
        "Затвор",
        "Бутало",
        "Дъно",
        "Дъно-с-център",
        "Бутален-прът",
        "Тръба",
      ],
      fieldTypes: {
        Затвор: ["øAL", "øS", "øD"],
        Бутало: ["øAL", "øI"],
        Дъно: ["øAL", "øТ"],
        "Дъно-с-център": ["øAL", "øТ"],
        "Бутален-прът": ["øS", "Kg/М"],
        Тръба: ["øAL", "øD"],
      },
      selectedPart: "Затвор",
      openItems: ["Затвор"],
      viewType: "grid",
      searchSTR: 0,
      øAL: 0,
      øS: 0,
      K: 0,
    };
    this.menu = React.createRef();
  }

  openItem = (data) => {
    this.setState({ viewType: (data.type == "K0" && "grid") || "list" });
  };
  menuHover = (id) => {
    let map = {
      o: cyl_example_o_prasteni,
      yp: cyl_example_uplatnitel_za_butalo,
      zatvor: cyl_example_zatvor,
      clear: cyl_example_chistach,
    };
    this.menu.current.src = map[id] || cyl_example;
  };

  // componentDidMount = () => {
  //   let keyword = 'taxed-price-value">';
  //   let req = new XMLHttpRequest();

  //   req.open(
  //     "GET",
  //     "https://satelit-nikis.bg/product/2492/hidravlichno-uplatnenie-tip-a154-50x60x7-pu92.html?fbclid=IwAR3pObevtDMtxBDurb8ncutfBQKATLroQKLOA-VAvAqP_tDw22-MWQG6Z2I"
  //   );
  //   req.send(null);
  //   req.onreadystatechange = function () {
  //     if (req.status == 200 && req.readyState == 4) {
  //       let spIndex = req.responseText.indexOf(keyword) + keyword.length;
  //       let firstfind = req.responseText.substring(spIndex, spIndex + 10);
  //       console.log(firstfind.slice(0, firstfind.indexOf("лв")));
  //     }

  //     // c-price-exclude-taxes__no-wholesale-price-list-price taxed-price-value
  //   };
  // };

  setVal = (val) => {
    document.getElementById("myInput").value = val;
    this.setState({ searchSTR: val });
  };

  setOpen = (key, openItems) => {
    let updated = openItems.filter((item) => item !== key);
    if (openItems.indexOf(key) === -1) {
      updated = [...openItems, key];
    }
    this.setState({ openItems: updated });
  };

  render() {
    let {
      viewType,
      searchSTR,
      data,
      types,
      fieldTypes,
      selectedPart,
      openItems,
      øAL,
      øS,
      K
    } = this.state;





    const calk = (type, type2, param, defout) => {
      let res = data[`${type2}`].filter((item) => {
        return item[`${type}`] == param;
      })[0];
      return (res && res.name) || defout;
    };

    const getItem = (prop, type, param) => {
      let noResult = { name: type, øI: prop };
      let res = data[`${type}`].filter((item) => {
        return item[`${prop}`] == param;
      })[0];
      return (res && res) || noResult;
    };

    const findItem = (type, conf) => {
      let noResult = { name: type, ...conf };
      let res = data[`${type}`].filter((item) => {

        return Object.keys(conf).every(key => item[key] == conf[key])
      })[0];
      return (res && res) || noResult;
    };

    let labels = {
      appName: 'ХСИ',
      butalo_diam: 'диаметър бутало',
      prut_diam: 'диаметър прът',
      hod: 'ХОД',
    }

    let butalo = findItem("Бутало", { "øAL": øAL });
    let truba = getItem("øAL", "Тръба", øAL, 0)
    let gaika = getItem("øI", "Гайка", butalo["øI"]);
    let zatvor = findItem("Затвор", { "øAL": øAL, 'øS': øS });
    let duno = getItem("øAL", "Дъно-с-център", øAL);
    let gap = 5;

    let result = (1 + gaika["L"] + butalo["L"] + K + zatvor["L"] + 15 + gap);

    let resultPipe = (1 + duno["S1"] + gaika["L"] + butalo["L"] + K + zatvor["L1"] + gap);

    return (
      <div className="App">
        <header className="App-header">
          <p>{labels.appName}</p>
          <div style={{ textAlign: 'left' }}>

            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <p>{labels.butalo_diam}</p>
                <input type="text" style={{ width: 50 }} onChange={(e) => { this.setState({ øAL: e.currentTarget.value }) }} />
              </div>

              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <p>{labels.prut_diam}</p>
                <input type="text" style={{ width: 50 }} onChange={(e) => { this.setState({ øS: e.currentTarget.value }) }} />
              </div>

              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <p>{labels.hod}</p>
                <input type="text" style={{ width: 50 }} onChange={(e) => { this.setState({ K: Number(e.currentTarget.value) }) }} />
              </div>

            </div>
            <p>{` Хромиран бутален прът ф${this.state.øAL} L- *+${gap} мм = ${result} мм`}</p>
            <p>{` Прецизна ${truba['name']} L- *+${gap} мм = ${resultPipe} мм`}</p>
            <p>{` ${zatvor['name']}, ${zatvor['code']} `}</p>
            <p>{`+ комплект уплътнители за  ${zatvor['name']}, ${zatvor['SealKit']} `}</p>
            <p>{` ${butalo['name']}, ${butalo['code']} `}</p>
            <p>{`+ комплект уплътнители за  ${butalo['name']}, ${butalo['SealKit']} `}</p>
            <p>{` ${duno['name']}, ${duno['code']} `}</p>
            <p>{` ${gaika['name']}, ${gaika['code']} `}</p>

            <p>{calk("øAL", "Тръба", øAL, 0)}</p>
            <p>{calk("øS", "Бутален-прът", øS, 0)}</p>
            <p> {zatvor['name']}</p>
            <p>{calk("øAL", "Бутало", øAL, 0)}</p>
            <p>{getItem("øAL", "Дъно-с-център", øAL)["name"]}</p>
            <p> {getItem("øI", "Гайка", butalo["øI"])["name"]} </p>

          </div>
          {/* <form>
            <Select
              items={types}
              plaseholder={'Izberi'}
              onChangeHandler={item => this.setState({ selectedPart: item })}
              style={{}}
            />
            
            <div className="horisontal">
              {fieldTypes[selectedPart].map((field) => (
                <div className="horisontal">
                  {field}
                  <Select
                    items={[...new Set(data[selectedPart].map(item => item[field]))]}
                    plaseholder={'Izberi'}
                    onChangeHandler={item => this.setState({ [field]: item })}
                    style={{}}
                  />

                </div>
              ))}
            </div>
          </form> */}

          {/* <ul class="list-group">
            {types.map((key) => {
              let items = data[key].filter((item) => {
                return fieldTypes[key].every((k) => {
                  let selected = this.state[k];
                  let current = item[k];
                  if (selected) {
                    return current == selected;
                  } else return false;
                });
              });
              return (
                <li onClick={e => this.setOpen(key, openItems)}>
                  <div className="row">
                    {key}
                    <span class="badge">{items.length}</span>
                  </div>

                  <ul>
                    {openItems.indexOf(key) > -1 ? items.map((item) => <li>{Object.keys(item).map(inKey => `\n${inKey}: ${item[inKey]}`)}</li>) : ''}
                  </ul>
                </li>
              );
            })}
          </ul> */}
        </header>

        {/* <header className="App-header">
        
        
      </header> 
      <Bar/>
      <InputTag data ={products} />
      <div className={viewType == 'list' && 'list-container' || "grid-container"}>
       
        <ProductCard data={9} onItemSelect={this.openItem}/> 
        {
          products.rings.map(pr=><ProductCard data={pr} key={pr.name} onItemSelect={this.openItem}/>)
        }
          
      </div>
      <div>Count: {count}</div>

      <button onClick={() => increaseCounter()}>Increase Count</button>

      <button onClick={() => decreaseCounter()}>Decrease Count</button> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    count: state.counter.count,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    increaseCounter: () => dispatch(increaseCounter()),

    decreaseCounter: () => dispatch(decreaseCounter()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);

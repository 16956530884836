import React from "react";
import { connect } from "react-redux";
import './ProductCard.css';

class ProductCard extends React.Component {
  constructor(props) {
    super(props)
    let {data} = this.props;
    this.state = { data }
  }
  //backgroundColor:' #282c34'
  render (){
    let {data}=this.state;
    let { onItemSelect } = this.props;
    return (
      <div key ={`${data.type}_${data.name}_${data.manufacturer}`} className={"grid-item"} onClick={() => onItemSelect && onItemSelect(data)} >
        <div className={'infoPart'} style={{flex:0.1, backgroundColor:' gray'}} >
           <img src={data.img} />
        </div>
        <div className={'infoPart'} style={{flex:0.2}} > 
        
           {data.type}
        </div>
        <div className={'infoPart'} style={{flex:0.5, backgroundColor:'gray'}} >
          {data.name}
        </div>
        <div className={'infoPart'} style={{flex:0.2}} >
           {data.manufacturer}
        </div>
      </div>
    )
  }
    

}
let mapStateToProps = state => {
    return {
      count: state.counter.count,
    }
  }
  
  let mapDispatchToProps = dispatch => {
    return { 
    }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(ProductCard)